
.portfolio-project {
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 85%;
    overflow: auto;
    margin-top: 100px;
  
    h1.page-title {
      margin-left: 100px;
      margin-top: 10px;
  }
  
    .portfolio-row {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      margin-left: 50px;

  
      .portfolio-column {
        flex: 1;
        margin: 25px 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
  
        p {
          font-size: 18px;
          line-height: 1.5;
          margin-bottom: 20px;
        }
  
        img {
          max-width: 80%;
          height: auto;
          margin-left: 40px;
          margin-bottom: 20px;
          
        }
  
        &:nth-child(odd) {
          order: 1;
        }
  
        &:nth-child(even) {
          order: 2;
        }
  
        &:last-child {
          margin-bottom: 0;
        }

        .alterraLink {
          color: #FE54BF;
          text-decoration: none;
        }
      }
    }
  }